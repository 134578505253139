import SideBar from "./SideBar";
import { ReactComponent as Icon } from "../Images/wallet.svg";
import { ReactComponent as Dollar } from "../Images/dollar.svg";
import { ReactComponent as Search } from "../Images/search.svg";
import { ReactComponent as Calendar } from "../Images/calendar.svg";
import usa from "../Images/United States of America (US).png";
import add from "../Images/add.svg";
import email from "../Images/email.svg";
import user from "../Images/user.svg";
import map from "../Images/map.svg";
import Select from "react-select";
import cancel from "../Images/Cancel.svg";
import Footer from "./Footer";
import { useEffect, useState } from "react";
import WalletTable from "./component/WalletTable";
import AdminRequest from "../../utils/services/adminRequests";
import WalletDetialsTable from "./component/walletDetailsTable";
import WalletRequest from "../../utils/services/walletRequests";
import { CurrencyFormatter, formatDate } from "../../utils/helpers/helper";
import AllUserTransactionsTable from "./component/allUserTransactions";
import TextInput from "../shared/input/TextInput";
import { useFormik } from "formik";
import { toast } from "react-toastify";

const currency2 = [
  {
    id: 1,
    title: "USD History",
  },
  {
    id: 2,
    title: "NGN History",
  },
];
function Wallet() {
  const [modal, setModal] = useState(false);
  const [selectedCurrency2, setSelectedCurrency2] = useState(currency2[0]);
  const [addExchangeRate, setAddExchangeRate] = useState(false);
  const [transDetials, setTransDetails] = useState([]);
  const [isUserTrans, setUserTrans] = useState([]);
  const [isSelected, setSelected] = useState({});
  const [dollarRate, setDollarRate] = useState([]);
  const [todayRate, setTodayRate] = useState("");
  const [dollarBalance, setDollarBaance] = useState(0);
  const [nairaBalance, setNairaBalance] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const getDollarLogs = async () => {
    await WalletRequest.getDollarLogs().then((res) => {
;
      setDollarRate(res.data.result);
    });
  };
  const getallUser = async () => {
    await AdminRequest.getApprovedUsers().then((res) => {
      ;
      if (res?.data?.result !== undefined) {
        setAllUsers(res?.data?.result);
      }
    });
  };

  const getAllUserTransaction = async () => {
    await WalletRequest.getUserTransactions().then((res) => {

      setUserTrans(res.data.result);
    });
  };

  // gets transactions
  const getUserWallet = async (mail) => {
    await AdminRequest.getUserWallet(mail).then((res) => {
      // ;
      if (res?.data?.result !== undefined) {
        setTransDetails(res?.data?.result);
      }
    });
  };

  const getUserDollarWallet = async (mail) => {
    await AdminRequest.getUserWalletDollar(mail).then((res) => {
      // ;
      if (res?.data?.result !== undefined) {
        setTransDetails(res?.data?.result);
      }
    });
  };

  // get dollar and naira babalnce

  const getBalance = async () => {
    await WalletRequest.getDollarBalanceAdmin().then((res) => {
      setDollarBaance(res.data.result);
    });

    await WalletRequest.getNairaBalanceAdmin().then((res) => {

      setNairaBalance(res.data.result);
    });
  };
  const getDollarRateToday = async () => {
    await WalletRequest.getCurrentDollarRate().then((res) => {
      setTodayRate(res?.data?.result || 0);
    });
  };
  useEffect(() => {
    getallUser();
    getDollarLogs();
    getAllUserTransaction();
    getBalance();
    getDollarRateToday();
  }, []);

  const cards = [
    {
      id: 1,
      name: "Total Amount in Dollars ($)",
      icon: <Icon />,
      amount: CurrencyFormatter(dollarBalance || 0, "$"), 
      back: "#D5EFFF",
      border: "#E8F7FF",
      text: "#0C4AFF",
    },
    {
      id: 2,
      name: (
        <div>
          Today’s Rate <b>$</b> (USD)
        </div>
      ),
      icon: <Dollar />,
      amount:   <>{CurrencyFormatter(todayRate || 0, "$")}</>,
      back: "#CAFDF3",
      border: "#EFFEFA",
      text: "#0A7B75",
    },
    {
      id: 3,
      name: "Total Amount in Naira (₦)",
      icon: <Icon />,
      amount: CurrencyFormatter(nairaBalance || 0),
      back: "#DFF9E4",
      border: "#F1FCF2",
      text: "#1F7634",
    },
  ];
  const formatOptionLabel2 = ({ title }) => (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <span className="placeholder:text-[#4a4f54] 100:truncate text-[#4a4f54] text-xs font-medium">{title}</span>
    </div>
  );

  const openDetails = (itemInfo) => {

    setModal(true);
    setSelected(itemInfo);
    getUserDollarWallet(itemInfo?.email);
  };

  const handleSelect2 = (selectedOption2) => {
    if (selectedOption2.id === 1) {
      getUserDollarWallet(isSelected?.email);
    } else {
      getUserWallet(isSelected?.email);
    }

    setSelectedCurrency2(selectedOption2);
  };

  const formik = useFormik({
    initialValues: {
      amount: 0,
    },
    onSubmit: async (values) => {
      setLoading(true);
      await AdminRequest.updateDollarRate(values.amount).then((res) => {
        ;
        setLoading(false);
        if (res.data.statusCode === 200) {
          toast.success("successful", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setAddExchangeRate(false);

          getDollarLogs();
          getDollarRateToday();
        } else {
          toast.error(res?.data?.message || res?.message, {
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
        }
      });
    },
  });
  return (
    <>
      <SideBar />
      <div className=" flex 100:justify-center items-center lg:justify-start flex-col 100:pt-[120px] md:pt-[120px] lg:pt-[40px] md:py-[32px]  lg:pr-8 w-full lg:pl-[362px]">
        <div className="flex gap-[32px] items-center 100:w-[85%] lg:w-full  justify-center flex-col">
          <div className=" flex justify-between items-center w-full">
            <h3 className="self-stretch  text-[#3a3c3f] text-2xl font-medium leading-[38px]">Wallet</h3>
            <button onClick={() => setAddExchangeRate(true)} className="px-4 py-2.5 h-10 bg-[#0c4cff] rounded-lg shadow border border-[#0c4cff] justify-center items-center gap-2 flex text-white text-sm font-medium  leading-tight">
              <img alt="Add" src={add} />
              Add Exchange Rate
            </button>
          </div>

          {/* CONTRIBUTION CARDS */}
          <div className=" w-full flex-col justify-start items-center gap-[8px] inline-flex">
            <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">Wallet Overview</h5>
            <div className=" w-full flex-1 flex 100:flex-wrap gap-[16px] xl:flex-nowrap justify-start items-start">
              {cards.map((item) => (
                <div key={item.id} className="h-fit  flex-grow lg:w-[348px] p-6 bg-white rounded-lg shadow border border-[#cdd1d4] justify-between items-start  inline-flex">
                  <div className=" w-fit flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 className="text-[#4a4f54] text-sm font-normal leading-tight">{item.name}</h4>
                    <h3 className="text-[#3a3c3f] text-2xl font-semibold ">{item.amount}</h3>
                  </div>
                  <div
                    style={{
                      color: item.text,
                      backgroundColor: item.back,
                      borderColor: item.border,
                    }}
                    className={`w-12 h-12  flex justify-center items-center rounded-[28px]  border-8 `}
                  >
                    {item.icon}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className=" w-full justify-end items-end flex">
            <div className="h-9 w-fit 100:hidden md:flex text-[#6c757d] text-sm font-medium leading-tight rounded-lg px-3.5 py-2 bg-white  shadow border border-[#aab0b6] justify-center items-center gap-2 flex">
              <Calendar />
              Filter by date
            </div>
          </div>
          {/* TABLE 1 */}
          <div className=" w-full h-fit gap-[8px] flex-col justify-start items-center  inline-flex">
            <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">All Wallet</h5>
            <div className=" w-full flex 100:flex-col 100:gap-[24px] xl:flex-row justify-start items-start xl:gap-[16px]">
              <WalletTable data={allUsers} onClick={openDetails} />
              <div className="xl:w-[360px] 1xl:w-[420px] 100:w-full h-full overflow-y-scroll px-4 py-6 bg-white rounded-lg shadow border border-[#e5e7e8] flex-col justify-start items-center gap-8 inline-flex">
                <div className="h-28 w-full px-12 py-8 bg-[#e8f7ff] rounded-lg justify-center items-center inline-flex">
                  <div className="self-stretch h-12 flex-col justify-start items-center gap-1 flex">
                    <h5 className="opacity-60 text-center text-[#4a4f54] text-xs font-medium ">Todays USD Rate</h5>
                    <h3 className="text-center text-[#4a4f54] text-2xl font-semibold ">
                      {" "}
                      <>{CurrencyFormatter(todayRate || 0, "$")}</>
                    </h3>
                  </div>
                </div>
                <div className=" w-full p-2.5 flex-col justify-start items-start gap-6 inline-flex">
                  <h3 className="text-[#3a3c3f] text-base font-medium ">Past Rate</h3>
                  <div className=" w-full py-2.5 flex-col justify-start items-start gap-4 inline-flex">
                    {Array.isArray(dollarRate) &&
                      dollarRate?.map((item) => (
                        <div className=" w-full border-b py-[8px] px-[4px] border-b-[#E5E7E8] flex-col justify-start items-start gap-1.5 flex">
                          <div className=" w-full justify-start items-center gap-[6px] inline-flex">
                            <img className=" w-[30px] h-[30px]" src={usa} alt="usa" />

                            <div className=" w-full flex-col justify-start items-start gap-1 inline-flex">
                              <h4 className="text-[#4a4f54] text-[10px] font-semibold  leading-[14px]">USD</h4>
                              <h5 className="text-[#6c757d] text-[10px] font-medium ">{formatDate(new Date(item?.date))}</h5>
                            </div>
                            <div className="text-center text-[#4a4f54] text-xs font-medium ">{CurrencyFormatter(item.amount || 0, "$")}</div>
                          </div>
                        </div>
                      ))}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" w-full gap-[8px] flex-col justify-start items-center  inline-flex">
            <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">All Transactions</h5>

            <div className="h-fit w-full whitespace-nowrap  100:overflow-x-scroll bg-white rounded-xl shadow flex-col justify-center items-start gap-[24px] inline-flex">
              <div className=" flex justify-between items-center w-full p-[20px]">
                <div className="self-stretch gap-[12px]w-[400px] px-3.5 py-2.5 bg-white rounded-lg shadow border border-[#aab0b6] justify-start items-center  inline-flex text-[#6c757d] text-base font-normal leading-normal">
                  <Search /> <input className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full" placeholder="Search" />
                </div>
              </div>
              <AllUserTransactionsTable data={isUserTrans} />
            </div>
          </div>

          <Footer />
        </div>
      </div>
      {modal && (
        <>
          <div className="w-full gap-[40px] h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start items-center flex">
            <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
              <div onClick={() => setModal(false)} className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  ">
                <img className=" " src={cancel} alt="Cancel" />
              </div>
            </section>
            <div className=" w-full h-[80vh]  flex justify-center items-center">
              <section className="w-[80%] h-full 1xl:w-[1129px] overflow-y-scroll rounded-xl flex ">
                <div className=" w-full   h-fit p-[32px] gap-[32px] bg-white rounded-xl shadow flex-col justify-center items-center flex">
                  <div className=" w-full flex justify-between">
                    <div className=" w-[100px]"></div>
                    <h4 className=" text-[24px] font-medium text-[#3A3C3F]">Wallet History</h4>
                    <div className="text-[#6c757d] text-sm font-medium h-[29px] px-4 py-1.5 bg-[#effefa] rounded-[999px] border border-[#cafdf3] justify-center items-center gap-2.5 inline-flex">
                      {isSelected?.walletDetailsResponse?.nairaWalletNumber}
                    </div>
                  </div>

                  <div className="w-full justify-start items-start gap-4 inline-flex">
                    <div className=" flex-col justify-start items-start gap-4 inline-flex">
                      <div className=" w-full justify-between items-center inline-flex">
                        <h3 className="grow shrink basis-0 text-[#6c757d] text-xs font-medium ">Wallet Transactions</h3>
                        <Select
                          className="  justify-start items-center gap-2 inline-flex text-[#4a4f54]  placeholder:text-[#4a4f54] text-xs font-medium  "
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: "#4a4f54",
                              borderColor: "#d5efff",
                              // color: "#4a4f54",
                              placeholder: "#4a4f54",
                              primary: "#D5EFFF",
                            },
                          })}
                          styles={{
                            control: (base) => ({
                              ...base,

                              width: "fit",
                              height: "27px",
                              outline: "none",
                              paddingLeft: "12px",
                              paddingRight: "12px",
                              color: "#4a4f54",
                              // border: "#4a4f54",
                              zIndex: "20",
                              borderColor: "#d5efff",
                              borderWidth: "1px",
                              borderRadius: "999px",
                            }),
                          }}
                          value={selectedCurrency2}
                          onChange={handleSelect2}
                          options={currency2}
                          formatOptionLabel={formatOptionLabel2}
                          // placeholder="Select currency"
                        />
                      </div>
                      <WalletDetialsTable data={transDetials} onClick={() => {}} dollar={selectedCurrency2.id === 1 ? true : false} />
                      <Footer />
                    </div>
                    <div className="w-full 100:hidden lg:flex h-fit flex-col justify-start items-start gap-4 inline-flex">
                      <div className="h-fit w-full p-6 bg-white rounded-lg shadow border border-[#cdd1d4] justify-between items-start  inline-flex">
                        <div className=" w-fit flex-col justify-start items-start gap-2.5 inline-flex">
                          <h4 className="text-[#4a4f54] w-full  text-sm font-normal leading-tight">Total No of Transactions</h4>
                          <h3 className="text-[#3a3c3f] text-2xl font-semibold ">{transDetials.length}</h3>
                        </div>
                        <div
                          style={{
                            backgroundColor: "#D5EFFF",
                            border: "#E8F7FF",
                            color: "#0C4AFF",
                          }}
                          className={`w-[40px] h-[40px] flex justify-center items-center rounded-[28px]  border-8 `}
                        >
                          <Icon className=" w-[20px] h-[20px]" />
                        </div>
                      </div>
                      <div className="h-fit w-full p-6 bg-white rounded-lg shadow border border-[#cdd1d4] justify-between items-start  inline-flex">
                        <div className=" w-fit flex-col justify-start items-start gap-2.5 inline-flex">
                          <h4 className="text-[#4a4f54] text-sm font-normal leading-tight">Total Amount of Transactions </h4>
                          <h3 className="text-[#3a3c3f] text-2xl font-semibold ">
                            {selectedCurrency2.id === 1 ? (
                              <>
                                {CurrencyFormatter(
                                  transDetials?.reduce((acc, item) => acc + item.amountReceived, 0),
                                  "$"
                                )}
                              </>
                            ) : (
                              <>{CurrencyFormatter(transDetials?.reduce((acc, item) => acc + item.amountReceived, 0))}</>
                            )}
                          </h3>
                        </div>
                        <div
                          style={{
                            backgroundColor: "#D5EFFF",
                            border: "#E8F7FF",
                            color: "#0C4AFF",
                          }}
                          className={`w-[40px] h-[40px] flex justify-center items-center rounded-[28px]  border-8 `}
                        >
                          <Icon className=" w-[20px] h-[20px]" />
                        </div>
                      </div>
                      <div className="h-fit w-full p-6 bg-white rounded-lg shadow border border-[#cdd1d4] flex-col justify-start items-center gap-12 inline-flex">
                        <div className="flex-col w-full  pb-[16px] justify-start items-center gap-[16px] inline-flex">
                          {/* <div className="justify-start w-full items-center gap-3 inline-flex">
                            <img src={no} alt="" /> <h4 className="self-stretch text-[#4a4f54] text-sm font-medium leading-tight">{isSelected?.id}</h4>
                          </div>{" "} */}
                          <div className="justify-start w-full items-center gap-3 inline-flex">
                            <img src={email} alt="" /> <h4 className="self-stretch text-[#4a4f54] text-sm font-medium leading-tight">{isSelected?.email}</h4>
                          </div>{" "}
                          <div className="justify-start w-full items-center gap-3 inline-flex">
                            <img src={user} alt="" />{" "}
                            <h4 className="self-stretch text-[#4a4f54] text-sm font-medium leading-tight">
                              {isSelected?.firstName} {isSelected?.lastName}
                            </h4>
                          </div>{" "}
                          <div className="justify-start w-full items-center gap-3 inline-flex">
                            <img src={map} alt="" /> <h4 className="self-stretch text-[#4a4f54] text-sm font-medium leading-tight">{isSelected?.address}</h4>
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </>
      )}
      {addExchangeRate && (
        <>
          <div className="w-full gap-[40px] h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start items-center flex">
            <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
              <div onClick={() => setAddExchangeRate(false)} className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  ">
                <img className=" " src={cancel} alt="Cancel" />
              </div>
            </section>
            <section className=" 100:w-[80%] h-full iphone:w-full  flex justify-center  items-center">
              <div className=" 100:w-full iphone:w-[480px]  h-fit  p-[24px]  bg-white rounded-xl shadow ">
                <div className="gap-[32px] h-fit w-full flex-col justify-center items-center flex">
                  <h4 className=" text-[18px] font-medium text-[#3A3C3F]">Add Exchange Rate</h4>
                  <form className="flex-col w-full flex gap-[24px]  justify-start items-start ">
                    {/* <label
                      className=" w-full flex flex-col justify-start items-start gap-[6px] leading-[20px] text-[#4A4F54] text-[14px] font-medium "
                      htmlFor="  Rate"
                    >
                     
                    </label> */}
                    <TextInput label={"Rate"} placeholder={"Add rate"} onChange={formik.handleChange("amount")} value={formik.values.amount} type={"number"} />
                    <button onClick={formik.handleSubmit} className="leading-[20px] border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-fit px-[20px] py-[12px] w-full ">
                      {isLoading ? "Loading..." : "Submit"}
                    </button>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default Wallet;
