import { useRef } from "react";
import ContactUs from "../LandingPage/ContactUs";
import Footer from "../LandingPage/Footer";
import Header from "../LandingPage/Header";

function Privacy() {
  const contact = useRef(null);
  const faq = useRef(null);
  const home = useRef(null);
  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <>
   <Header
        scrollToSection={scrollToSection}
        home={home}
        contact={contact}
        faq={faq}
      />
      <div className="flex justify-center flex-col pb-[96px] h-fit 100:mt-[180px] md:mt-[150px] items-center  w-full">
        <section className="w-full bg-[#063CCD]  py-[96px] h-fit flex justify-center items-center ">
          <div className=" flex flex-col justify-center items-center gap-[24px] 100:w-[90%] md:w-[650px]">
            <h2 className=" text-center text-white text-5xl font-semibold leading-10">Privacy Policy</h2>
            <p className=" text-center text-sky-200 text-xl font-normal  leading-loose">
              At Greenquarters Ltd., we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our platform, ijefund.com.{" "}
            </p>
          </div>
        </section>
        <section className="py-[96px] 100:w-[85%] flex-col gap-[48px] md:w-[720px] h-fit flex justify-center items-center">
          <ol className="w-full list-decimal flex-col justify-start items-start gap-[48px] inline-flex">
            <li className="self-stretch  text-neutral-700 text-3xl font-semibold ">
              Information We Collect
              <p className="self-stretch text-gray-500 text-lg font-normal  mt-[24px]">
                a. Personal Information: We may collect personal information such as your name, email address, phone number, postal address, and payment information when you register on our Platform or make a donation.
                <br />
                <br />
                b. Non-Personal Information: We may collect non-personal information such as browser type, operating system, and the pages you visit on our Platform.{" "}
              </p>
            </li>
            <li className="self-stretch  text-neutral-700 text-3xl font-semibold ">
              We may use the information we collect for various purposes, including:
              <ul className="self-stretch list-disc text-gray-500 text-lg font-normal  mt-[24px]">
                <li>To provide and maintain our services.</li>
                <br />
                <li>To process transactions and send you related information.</li>
                <br />
                <li>To communicate with you, including sending updates and promotional materials.</li>
                <br />
                <li>To monitor and analyze usage of the Platform.</li>
                <br />
                <li>To detect, prevent, and address technical issues.</li>
              </ul>
            </li>
            <li className="self-stretch  text-neutral-700 text-3xl font-semibold ">
              Sharing Your Information
              <br />
              <br />
              <p className="self-stretch text-gray-500 text-lg font-normal  ">
                We do not sell, trade, or otherwise transfer your personal information to outside parties except as described below: <br />
              </p>
              <ul className="self-stretch list-disc text-gray-500 text-lg font-normal  mt-[24px]">
                <li>With service providers who assist us in operating the Platform and conducting our business.</li>
                <br />
                <li>When required by law or to comply with legal processes.</li>
                <br />
                <li>To protect our rights, privacy, safety, or property, and/or that of our affiliates, you, or others.</li>
              </ul>
            </li>
            <li className="self-stretch  text-neutral-700 text-3xl font-semibold ">
              Data Security
              <br />
              <br />
              <p className="self-stretch text-gray-500 text-lg font-normal  ">
                We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the Internet or method of electronic storage is 100% secure.{" "}
              </p>
            </li>
            <li className="self-stretch  text-neutral-700 text-3xl font-semibold ">
              Your Data Protection Rights
              <br />
              <br />
              <p className="self-stretch text-gray-500 text-lg font-normal  ">
                We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the Internet or method of electronic storage is 100% secure.{" "}
              </p>
              <ul className="self-stretch list-disc text-gray-500 text-lg font-normal  mt-[24px]">
                <li>The right to access – You have the right to request copies of your personal data.</li>
                <br />
                <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate or complete information you believe</li>
              </ul>
            </li>
          </ol>
        </section>
      </div>
      <section ref={contact}>
      <ContactUs />
      </section>
      <Footer />
    </>
  );
}

export default Privacy;
