import { useEffect, useState } from "react";
import SideBar from "./SideBar";
import { ReactComponent as Icon } from "../Images/Usersmanagement.svg";
import { ReactComponent as Completed } from "../Images/complete.svg";
import { ReactComponent as Search } from "../Images/search.svg";
import { ReactComponent as Pending } from "../Images/pending.svg";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import UserManagementTable from "./component/userManagementTable";
import AdminRequest from "../../utils/services/adminRequests";

function UserManagement() {
  const [search, setSearch] = useState(false);
  const navigate = useNavigate();
 
  const [allUsers, setAllUsers] = useState([]);
  const [approvedUsers, setapprovedUsers] = useState([]);
  const [pendingUsers, setpendingUsers] = useState([]);
  const [allRejectedUsers, setRejectedUsers] = useState([]);


  const getallUser= async () => {
    await AdminRequest.getAllUsers().then((res) => {
      ;
      if (res?.data?.result !== undefined) {
        setAllUsers(res?.data?.result);
      }
    });
  };


  const getrejectedUser= async () => {
    await AdminRequest.getRejectedUsers().then((res) => {
      ;
      if (res?.data?.result !== undefined) {
        setRejectedUsers(res?.data?.result);
      }
    });
  };

  const getApproved = async () => {
    await AdminRequest.getApprovedUsers().then((res) => {
      ;
      if (res?.data?.result !== undefined) {
        setapprovedUsers(res?.data?.result);
      }
    });
  };

  const getPending = async () => {
    await AdminRequest.getPendingUsers().then((res) => {


      if (res?.data?.result !== undefined) {
        setpendingUsers(res?.data?.result);
      }
    });
  };

  useEffect(() => {
    getallUser()
    getApproved();
    getrejectedUser()
    getPending();
  }, []);



  const cards = [
    {
      id: 1,
      name: "Total Number of Users",
      icon: <Icon />,
      amount:  ` ${allUsers?.length}`,
      back: "#D5EFFF",
      border: "#E8F7FF",
      text: "#0C4AFF",
    },
    {
      id: 2,
      name: "Active Users",
      icon: <Icon />,
      amount: `${approvedUsers?.length}`,
      back: "#DFF9E4",
      border: "#F1FCF2",
      text: "#1F7634",
    },
    {
      id: 3,
      name: "Inactive Users",
      icon: <Icon />,
      amount: `${allRejectedUsers.length + pendingUsers.length}`,
      back: "#FEE6E5",
      border: "#FEF2F2",
      text: "#B7192C",
    },
  ];

  return (
    <>
      <SideBar />
      <div className=" flex 100:justify-center items-center lg:justify-start flex-col 100:pt-[120px] md:pt-[120px] lg:pt-[40px] md:py-[32px]  lg:pr-8 w-full lg:pl-[362px]">
        <div className="flex gap-[32px] items-center 100:w-[85%] lg:w-full  justify-center flex-col">
          <h3 className="self-stretch  text-[#3a3c3f] text-2xl font-medium leading-[38px]">User Management</h3>
          {/* CONTRIBUTION CARDS */}
          <div className=" w-full flex-col justify-start items-center gap-[8px] inline-flex">
            <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">User Overview</h5>
            <div className=" w-full flex-1 flex 100:flex-wrap gap-[16px] xl:flex-nowrap justify-start items-start">
              {cards.map((item) => (
                <div key={item.id} className="h-fit  flex-grow lg:w-[348px] p-6 bg-white rounded-lg shadow border border-[#cdd1d4] justify-between items-start  inline-flex">
                  <div className=" w-fit flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 className="text-[#4a4f54] text-sm font-normal leading-tight">{item.name}</h4>
                    <h3 className="text-[#3a3c3f] text-2xl font-semibold ">{item.amount}</h3>
                  </div>
                  <div
                    style={{
                      color: item.text,
                      backgroundColor: item.back,
                      borderColor: item.border,
                    }}
                    className={`w-12 h-12  flex justify-center items-center rounded-[28px]  border-8 `}
                  >
                    {item.icon}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* TABLE 1 */}
          <div className=" w-full flex-col gap-[24px] justify-start items-center  inline-flex">
            <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">Approved Users</h5>
            <div className="w-full ">
              <UserManagementTable completed={true} data={approvedUsers} />
            </div>
          </div>
          {/* TABLE 2 */}
          <div className=" w-full flex-col gap-[24px] justify-start items-center  inline-flex">
            <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">Pending Users</h5>

            <div className="w-full ">
              <UserManagementTable data={pendingUsers} />
            </div>
          </div>


          <div className=" w-full flex-col gap-[24px] justify-start items-center  inline-flex">
            <h5 className="self-stretch text-[#6c757d] text-xs font-medium ">Inactive Users</h5>

            <div className="w-full ">
              <UserManagementTable data={allRejectedUsers} />
            </div>
          </div>
          {/* Footer */}
          <Footer />
        </div>
      </div>
    </>
  );
}

export default UserManagement;
