import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../Images/Logo.svg";
import menu from "../Images/HamburgerMenu.svg";
import cancel from "../Images/Cancel.svg";

function Header({ scrollToSection, contact, home, faq, howIjeWorks }) {
  const [hamburger, setHamburger] = useState(false);
  const [navBar, setNavBar] = useState(false);
  const navigate = useNavigate();
  const [activeNav, setActiveNav] = useState("");
  useEffect(() => {
    function handleScrollBackground() {
      if (window.scrollY >= 20) {
        setNavBar(true);
      } else {
        setNavBar(false);
      }
    }
    window.addEventListener("scroll", handleScrollBackground);

    return () => {
      window.removeEventListener("scroll", handleScrollBackground);
    };
  }, []);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveNav(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    const sections = [
      { id: "home", ref: home },
      { id: "contact", ref: contact },
      { id: "howIjeWorks", ref: howIjeWorks },
      { id: "faq", ref: faq },
    ];

    sections.forEach((section) => {
      if (section?.ref?.current) {
        observer.observe(section?.ref?.current);
      }
    });

    return () => {
      sections.forEach((section) => {
        if (section?.ref?.current) {
          observer.unobserve(section?.ref?.current);
        }
      });
    };
  }, [contact]);

  const handleNavClick = (section, sectionRef) => {

    setActiveNav(section);
    scrollToSection(sectionRef);
  };

  // console.log("locations", window.location.pathname);

  return (
    <>
      <header className={` z-10  fixed top-0 h-fit  w-full justify-center flex-col flex items-center`} scrollToSection={scrollToSection}>
        {/* Advert */}
        <div className={` ${navBar ? " hidden" : "flex"} w-full 100:h-fit 100:whitespace-break-spaces iphone:whitespace-nowrap 100:p-[20px] iphone:h-[60px] text-[#F5F6F6] text-center  justify-center items-center bg-[#0C4AFF]`}>
          <p className=" w-full  font-medium 100:text-[14px] md:text-[20px]  ">
            We are taking applications for January ‘25. {" "}
            <Link onClick={() => window.scrollTo(0, 0)} to="/auth/register" className=" underline">
              Apply now
            </Link>
          </p>
        </div>
        <div className={` rounded-[50px] px-[30px] my-[10px] ${navBar ? "bg-[#D5EFFF] " : ""} h-[75px] flex justify-between items-center 100:w-[90%] lg:w-[90%]`}>
          <section className=" w-fit flex gap-[40px] justify-start items-center">
            {" "}
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              to="/"
            >
              <img className="" src={logo} alt="Ije" />
            </Link>
            <div className=" 100:hidden xl:flex  text-[16px] text-[#6C757D] font-medium justify-center gap-[32px] items-center">
              <Link onClick={() => window.scrollTo(0, 0)} to="/">
                <nav className={`hover:underline hover:text-[#0C4AFF]  ${window.location.pathname === "/" ? "underline text-[#0C4AFF] " : ""}`}>Home</nav>
              </Link>
              <Link onClick={() => window.scrollTo(0, 0)} to="/aboutije">
                <nav className={`hover:underline hover:text-[#0C4AFF]  ${window.location.pathname === "/aboutije" ? "underline text-[#0C4AFF] " : ""}`}>About Us</nav>
              </Link>
              <nav
                onClick={() => {
                  if (window.location.pathname === "/") {
                    handleNavClick("howIjeWorks", howIjeWorks);
                    // window.scrollTo(0, 0);
                    setHamburger(false);
                  } else {
                    navigate("/");
                  }
                }}
                className={`hover:underline hover:text-[#0C4AFF]  cursor-pointer ${activeNav === "howIjeWorks" ? "underline text-[#0C4AFF] " : ""}`}
              >
                How It Works
              </nav>
              <Link onClick={() => window.scrollTo(0, 0)} to="/campaign">
                <nav className={`hover:underline hover:text-[#0C4AFF]  ${window.location.pathname === "/campaign" ? "underline text-[#0C4AFF] " : ""}`}>Campaigns</nav>
              </Link>
              <nav
                onClick={() => {
                  if (window.location.pathname === "/") {
                    handleNavClick("faq", faq);
                    // window.scrollTo(0, 0);
                    setHamburger(false);
                  } else {
                    navigate("/");
                  }
                }}
                className={`hover:underline hover:text-[#0C4AFF]  cursor-pointer ${activeNav === "faq" ? "underline text-[#0C4AFF] " : ""}`}
              >
                FAQs
              </nav>
              <nav
                onClick={() => {
                  if (["/", "/aboutije", "/legal/termsofuse", "/legal/privacypolicy"].includes(window.location.pathname)) {
                    handleNavClick("contact", contact);
                    // window.scrollTo(0, 0);
                    setHamburger(false);
                  } else {
                    navigate("/");
                  }
                }}
                className={`hover:underline hover:text-[#0C4AFF]  cursor-pointer ${activeNav === "contact" ? "underline text-[#0C4AFF] " : ""}`}
              >
                Contact Us
              </nav>
            </div>
          </section>
          <img onClick={() => setHamburger(true)} className="100:flex xl:hidden " src={menu} alt="Menu" />
          <section className="  100:hidden xl:flex justify-center items-center gap-[12px]">
            <Link onClick={() => window.scrollTo(0, 0)} to="/auth/login">
              <div className=" border-solid leading-[20px] border-[1px] text-[14px] rounded-[8px] border-[#0C4AFF] text-[#4A4F54] font-medium h-fit px-[14px] py-[8px] w-fit"> Login</div>
            </Link>
            <Link onClick={() => window.scrollTo(0, 0)} to="/auth/register">
              <div className="leading-[20px] border-[#0C4AFF] border-solid border-[1px] rounded-[8px] bg-[#0C4AFF] text-[14px] text-[#fff] font-medium h-fit px-[14px] py-[8px] w-fit"> Sign Up</div>
            </Link>
          </section>
        </div>
      </header>
      {hamburger && (
        <>
          <div className={` transition-opacity   flex justify-between fixed left-0 top-0 z-30  bg-slate-700 bg-opacity-60 backdrop-blur-lg w-full h-full`}>
            <section className={` ${hamburger ? "translate-x-0" : "-translate-x-full"} transition-transform transform  shadow-lg w-[250px] bg-[#f5f6f6] gap-[40px] px-[40px] flex flex-col items-start  py-[25px] text-[16px]   font-medium `}>
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to="/"
              >
                <img className="" src={logo} alt="Ije" />
              </Link>
              <div className="  flex flex-col text-[16px] text-[#6C757D] font-medium justify-start gap-[32px] items-start">
                <Link onClick={() => window.scrollTo(0, 0)} to="/">
                  <nav className={`hover:underline hover:text-[#0C4AFF]  ${window.location.pathname === "/" ? "underline text-[#0C4AFF] " : ""}`}>Home</nav>
                </Link>
                <Link onClick={() => window.scrollTo(0, 0)} to="/aboutije">
                  <nav className={`hover:underline hover:text-[#0C4AFF]  ${window.location.pathname === "/aboutije" ? "underline text-[#0C4AFF] " : ""}`}>About Us</nav>
                </Link>
                <nav
                  onClick={() => {
                    if (window.location.pathname === "/") {
                      handleNavClick("howIjeWorks", howIjeWorks);
                      // window.scrollTo(0, 0);
                      setHamburger(false);
                    } else {
                      navigate("/");
                    }
                  }}
                  className={`hover:underline hover:text-[#0C4AFF]  cursor-pointer ${activeNav === "howIjeWorks" ? "underline text-[#0C4AFF] " : ""}`}
                >
                  How It Works
                </nav>
                {/* <nav className=" ">How It Works</nav> */}
                <Link onClick={() => window.scrollTo(0, 0)} to="/campaign">
                  <nav className={`hover:underline hover:text-[#0C4AFF]  ${window.location.pathname === "/campaign" ? "underline text-[#0C4AFF] " : ""}`}>Campaigns</nav>
                </Link>
                <nav
                  onClick={() => {
                    if (window.location.pathname === "/") {
                      handleNavClick("faq", faq);
                      // window.scrollTo(0, 0);
                      setHamburger(false);
                    } else {
                      navigate("/");
                    }
                  }}
                  className={`hover:underline hover:text-[#0C4AFF]  cursor-pointer ${activeNav === "faq" ? "underline text-[#0C4AFF] " : ""}`}
                >
                  FAQs
                </nav>
                <nav
                  onClick={() => {
                    if (["/", "/aboutije", "/legal/termsofuse", "/legal/privacypolicy"].includes(window.location.pathname)) {
                      handleNavClick("contact", contact);
                      // window.scrollTo(0, 0);
                      setHamburger(false);
                    } else {
                      navigate("/");
                    }
                  }}
                  className={`hover:underline hover:text-[#0C4AFF]  cursor-pointer ${activeNav === "contact" ? "underline text-[#0C4AFF] " : ""}`}
                >
                  Contact Us
                </nav>
                <Link onClick={() => window.scrollTo(0, 0)} to="/auth/login">
                  <div className=" border-solid leading-[20px] border-[1px] text-[14px] rounded-[8px] border-[#0C4AFF] text-[#4A4F54] font-medium h-fit px-[14px] py-[8px] w-fit"> Login</div>
                </Link>
                <Link onClick={() => window.scrollTo(0, 0)} to="/auth/register">
                  <div className="leading-[20px] border-[#0C4AFF] border-solid border-[1px] rounded-[8px] bg-[#0C4AFF] text-[14px] text-[#fff] font-medium h-fit px-[14px] py-[8px] w-fit"> Sign Up</div>
                </Link>
              </div>
            </section>
            <div onClick={() => setHamburger(false)} className="w-12 h-12 top-[25px] justify-center flex  items-center right-[40px] relative bg-gray-400 rounded-3xl  ">
              <img className=" " src={cancel} alt="Cancel" />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Header;
