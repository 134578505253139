import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SideBar from "./SideBar";
import { ReactComponent as Back } from "../Images/back.svg";
import { ReactComponent as Pending } from "../Images/pending.svg";
import { ReactComponent as Completed } from "../Images/complete.svg";
import { ReactComponent as Reject } from "../Images/reject.svg";
import userDet from "../Images/userDetails.png";
import pick from "../Images/file.svg";
import no from "../Images/no.svg";
import emailu from "../Images/email.svg";
import user from "../Images/user.svg";
import map from "../Images/map.svg";
import linkedin from "../Images/linkedin.svg";
import x from "../Images/x.svg";
import Instagram from "../Images/instagram.svg";
import facebook from "../Images/facebook.svg";
import confirm from "../Images/Confirm.svg";
import cancel from "../Images/Cancel.svg";
import { useParams } from "react-router-dom";
import AdminRequest from "../../utils/services/adminRequests";
import { useFormik } from "formik";
import { approveUserValidator } from "../../utils/services/validation";
import TextArea from "../shared/input/TextArea";
import { toast } from "react-toastify";
import { formatDate, CurrencyFormatter } from "../../utils/helpers/helper";
function UserDetails() {
  const { email } = useParams();

  const [accept, setAccept] = useState(false);
  const [reject, setReject] = useState(false);
  const [isLoading, setLoading] = useState("");
  const [isUser, setUser] = useState({});
  const navigate = useNavigate();
  const getUserDetails = async () => {
    await AdminRequest.getUserDetails(email).then((res) => {

      setUser(res.data.result);
    });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const formik = useFormik({
    validationSchema: approveUserValidator,
    initialValues: {
      comment: "",
    },
  });

  const declineRequest = async () => {
    setLoading(true);
    await AdminRequest.rejectRequest(email, formik.values.comment).then((res) => {
      setLoading(false);

      if (res.data.statusCode === 200) {
        toast.success("successful", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setReject(true);
      } else {
        setLoading(false);
        toast.error(res?.data?.message || res?.message, {
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });
      }
    });
  };
  const approveRequest = async () => {
    setLoading(true);
    await AdminRequest.approveRequest(email, formik.values.comment).then((res) => {
      setLoading(false);

      if (res.data.statusCode === 200) {
        toast.success("successful", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setAccept(true);
      } else {
        setLoading(false);
        toast.error(res?.data?.message || res?.message, {
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });
      }
    });
  };

  // console.log("isUser", isUser?.educationInformation?.internationalPassport);

  return (
    <>
      <SideBar />
      <div className=" flex 100:justify-center items-center lg:justify-start  100:pt-[120px] md:pt-[120px] lg:pt-[40px] md:py-[32px]  lg:pr-8 w-full  lg:pl-[352px]">
        <div className="flex  items-start 100:w-[85%] lg:w-full  justify-start 100:flex-col md:flex-row">
          <div className=" 100:w-full h-fit flex flex-col items-center justify-start pr-[24px] gap-[32px]  w-[85%]">
            <h3 className="self-stretch flex justify-start items-center gap-[16px] text-[#3a3c3f] text-base font-medium  leading-normal ">
              <Link onClick={() => window.scrollTo(0, 0)} className="text-[#aab0b6] text-sm font-medium leading-normal justify-start items-center gap-1 inline-flex" to="/approver/user-management">
                User Management <Back className=" w-[14px] h-[14px] rotate-180" />
              </Link>
              User Details
            </h3>
            <div className="flex flex-col items-center justify-start w-full gap-[16px]">
              <div className=" w-full float-start">
                <img className=" 100:w-full   lg:w-[60%] h-[300px] object-fill rounded-[16px]" src={isUser?.imageString} alt={(isUser?.firstName, isUser?.lastName)} />
              </div>
              <div className="flex flex-col items-center justify-start w-full gap-[24px]">
                <div className="w-full border-b border-b-[#E5E7E8] pb-[8px] flex-col justify-start items-start gap-2 inline-flex">
                  <div className="self-stretch text-[#aab0b6] text-xs font-medium ">Personal Details</div>
                </div>
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Full name</h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">
                    {isUser?.firstName} {isUser?.lastName}
                  </h4>
                </div>
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Email</h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">{isUser?.email}</h4>
                </div>
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Phone number</h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">{isUser?.phoneNumber}</h4>
                </div>
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Address</h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">{isUser?.address}</h4>
                </div>
                {/* DIVIDER */}
                <div className="w-full border-b border-b-[#E5E7E8] pb-[8px] flex-col justify-start items-start gap-2 inline-flex">
                  <div className="self-stretch text-[#aab0b6] text-xs font-medium ">Verification of Identity and Profile</div>
                </div>
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Linkedin</h3>
                  <a href={isUser?.educationInformation?.linkedInAddress} className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">
                    {isUser?.educationInformation?.linkedInAddress}
                  </a>
                </div>
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">International Passport Number</h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">{isUser?.educationInformation?.passportNumber}</h4>
                </div>
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Bank Verification Number (BVN)</h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">{isUser?.educationInformation?.bvn}</h4>
                </div>
                {/* FILE */}
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">International Passport</h3>
                  <a className="phone:w-[344px] 100:w-full" href={isUser?.educationInformation?.internationalPassport} download={true} target="_blank" rel="noopener noreferrer">
                    <div className=" gap-4 phone:w-[344px] 100:w-full h-16 p-2 px-[8px] bg-white rounded-lg justify-start items-center  inline-flex">
                      <div className="w-8 h-8  bg-[#d5efff] flex justify-center items-center rounded-[28px] border-4 border-[#e8f7ff] ">
                        <img src={pick} alt="" />
                      </div>
                      <div className=" w-full flex flex-col justify-start items-start gap-[4px]">
                        <div className=" flex-col justify-start items-start inline-flex">
                          <h4 className="text-[#4a4f54] text-sm font-medium  leading-tight">International Passport</h4>
                          <h5 className="text-[#6c757d] text-xs font-normal eading-tight">200 KB</h5>
                        </div>
                      </div>
                    </div>{" "}
                  </a>
                </div>
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight"> Twitter</h3>
                  <a href={isUser?.educationInformation?.twitterLink} className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">
                    {isUser?.educationInformation?.twitterLink}
                  </a>{" "}
                </div>{" "}
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight"> Instagram</h3>
                  <a href={isUser?.educationInformation?.instagramLink} className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">
                    {isUser?.educationInformation?.instagramLink}
                  </a>{" "}
                </div>
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight"> Facebook</h3>
                  <a href={isUser?.educationInformation?.facebookProfile} className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">
                    {isUser?.educationInformation?.facebookProfile}
                  </a>{" "}
                </div>
                {/* DIVIDER */}
                <div className="w-full border-b border-b-[#E5E7E8] pb-[8px] flex-col justify-start items-start gap-2 inline-flex">
                  <div className="self-stretch text-[#aab0b6] text-xs font-medium ">Educational Background</div>
                </div>
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">University/Polytechnic Attended</h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">{isUser?.educationInformation?.university}</h4>
                </div>
                {/* FILE */}
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Certificate</h3>
                  <a className="h-fit phone:w-[344px] 100:w-full" href={isUser?.educationInformation?.certificate} download={true} target="_blank" rel="noopener noreferrer">
                    <div className=" gap-4 phone:w-[344px] 100:w-full h-16 p-2 px-[8px] bg-white rounded-lg justify-start items-center  inline-flex">
                      <div className="w-8 h-8  bg-[#d5efff] flex justify-center items-center rounded-[28px] border-4 border-[#e8f7ff] ">
                        <img src={pick} alt="" />
                      </div>
                      <div className=" w-full flex flex-col justify-start items-start gap-[4px]">
                        <div className=" flex-col justify-start items-start inline-flex">
                          <h4 className="text-[#4a4f54] text-sm font-medium  leading-tight">Certificate</h4>
                          <h5 className="text-[#6c757d] text-xs font-normal eading-tight">200 KB</h5>
                        </div>
                      </div>
                    </div>{" "}
                  </a>
                </div>
                {/* FILE */}
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Transcript</h3>
                  <a
                    className=" gap-4 phone:w-[344px] 100:w-full h-16 p-2 px-[8px] bg-white rounded-lg justify-start items-center  inline-flex"
                    href={isUser?.educationInformation?.transcript}
                    download={true}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="w-8 h-8  bg-[#d5efff] flex justify-center items-center rounded-[28px] border-4 border-[#e8f7ff] ">
                      <img src={pick} alt="" />
                    </div>
                    <div className=" w-full flex flex-col justify-start items-start gap-[4px]">
                      <div className=" flex-col justify-start items-start inline-flex">
                        <h4 className="text-[#4a4f54] text-sm font-medium  leading-tight">Transcript</h4>
                        <h5 className="text-[#6c757d] text-xs font-normal eading-tight">200 KB</h5>
                      </div>
                    </div>
                  </a>{" "}
                </div>
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">School Admitted To</h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">{isUser?.educationInformation?.schoolAdmitted}</h4>
                </div>
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Course of Study</h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">{isUser?.educationInformation?.courseOfStudy}</h4>
                </div>
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Country</h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">{isUser?.educationInformation?.country}</h4>
                </div>
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Duration of Study</h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">{isUser?.educationInformation?.durationOfStudy}</h4>
                </div>
                {/* FILE */}
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Admission Letter</h3>
                  <a
                    className=" gap-4 phone:w-[344px] 100:w-full h-16 p-2 px-[8px] bg-white rounded-lg justify-start items-center  inline-flex"
                    href={isUser?.educationInformation?.admissionLetter}
                    download={true}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="w-8 h-8  bg-[#d5efff] flex justify-center items-center rounded-[28px] border-4 border-[#e8f7ff] ">
                      <img src={pick} alt="" />
                    </div>
                    <div className=" w-full flex flex-col justify-start items-start gap-[4px]">
                      <div className=" flex-col justify-start items-start inline-flex">
                        <h4 className="text-[#4a4f54] text-sm font-medium  leading-tight">Admission Letter</h4>
                        <h5 className="text-[#6c757d] text-xs font-normal eading-tight">200 KB</h5>
                      </div>
                    </div>
                  </a>{" "}
                </div>
                {/* DIVIDER */}
                <div className="w-full border-b border-b-[#E5E7E8] pb-[8px] flex-col justify-start items-start gap-2 inline-flex">
                  <div className="self-stretch text-[#aab0b6] text-xs font-medium ">Work Experience</div>
                </div>
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Employment Status</h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">{isUser?.educationInformation?.employmentStatus}</h4>
                </div>{" "}
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Years of Work Experience</h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">{isUser?.educationInformation?.yearsOfWorkExperience}</h4>
                </div>{" "}
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Name of Current Employer</h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">{isUser?.educationInformation?.currentEmployer}</h4>
                </div>{" "}
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Role</h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">{isUser?.educationInformation?.role}</h4>
                </div>{" "}
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Number of Months with Employer</h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">{isUser?.educationInformation?.monthsWithEmployer}</h4>
                </div>
                {/* FILE */}
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Employment Letter</h3>
                  <a
                    className=" gap-4 phone:w-[344px] 100:w-full h-16 p-2 px-[8px] bg-white rounded-lg justify-start items-center  inline-flex"
                    href={isUser?.educationInformation?.employmentLetter}
                    download={true}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="w-8 h-8  bg-[#d5efff] flex justify-center items-center rounded-[28px] border-4 border-[#e8f7ff] ">
                      <img src={pick} alt="" />
                    </div>
                    <div className=" w-full flex flex-col justify-start items-start gap-[4px]">
                      <div className=" flex-col justify-start items-start inline-flex">
                        <h4 className="text-[#4a4f54] text-sm font-medium  leading-tight">Employment Letter</h4>
                        <h5 className="text-[#6c757d] text-xs font-normal eading-tight">200 KB</h5>
                      </div>
                    </div>
                  </a>{" "}
                </div>
                {/* FILE */}
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">12 Months Bank Statement</h3>
                  <a
                    className=" gap-4 phone:w-[344px] 100:w-full h-16 p-2 px-[8px] bg-white rounded-lg justify-start items-center  inline-flex"
                    href={isUser?.educationInformation?.bankStatement}
                    download={true}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="w-8 h-8  bg-[#d5efff] flex justify-center items-center rounded-[28px] border-4 border-[#e8f7ff] ">
                      <img src={pick} alt="" />
                    </div>
                    <div className=" w-full flex flex-col justify-start items-start gap-[4px]">
                      <div className=" flex-col justify-start items-start inline-flex">
                        <h4 className="text-[#4a4f54] text-sm font-medium  leading-tight">12 Months Bank Statement</h4>
                        <h5 className="text-[#6c757d] text-xs font-normal eading-tight">200 KB</h5>
                      </div>
                    </div>
                  </a>{" "}
                </div>
                {/* DIVIDER */}
                <div className="w-full border-b border-b-[#E5E7E8] pb-[8px] flex-col justify-start items-start gap-2 inline-flex">
                  <div className="self-stretch text-[#aab0b6] text-xs font-medium ">Previous Work Experience</div>
                </div>
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Name of Employer</h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">{isUser?.educationInformation?.currentEmployer}</h4>
                </div>{" "}
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Role</h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">{isUser?.educationInformation?.position}</h4>
                </div>{" "}
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Number of Years with Employer</h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">{isUser?.educationInformation?.yearsOfWorkExperience}</h4>
                </div>
                {/* FILE */}
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Employment Letter</h3>
                  <a
                    className=" gap-4 phone:w-[344px] 100:w-full h-16 p-2 px-[8px] bg-white rounded-lg justify-start items-center  inline-flex"
                    href={isUser?.educationInformation?.employmentLetter}
                    download={true}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="w-8 h-8  bg-[#d5efff] flex justify-center items-center rounded-[28px] border-4 border-[#e8f7ff] ">
                      <img src={pick} alt="" />
                    </div>
                    <div className=" w-full flex flex-col justify-start items-start gap-[4px]">
                      <div className=" flex-col justify-start items-start inline-flex">
                        <h4 className="text-[#4a4f54] text-sm font-medium  leading-tight">Employment Letter</h4>
                        <h5 className="text-[#6c757d] text-xs font-normal eading-tight">200 KB</h5>
                      </div>
                    </div>
                  </a>{" "}
                </div>
                {/* DIVIDER */}
                <div className="w-full border-b border-b-[#E5E7E8] pb-[8px] flex-col justify-start items-start gap-2 inline-flex">
                  <div className="self-stretch text-[#aab0b6] text-xs font-medium ">Financial Requirements and Available Funds</div>
                </div>
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Currency</h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">{isUser?.educationInformation?.currency}</h4>
                </div>{" "}
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Tuition Fee</h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">
                    {/* {isUser?.educationInformation?.currency}{" "} */}
                    {CurrencyFormatter(parseFloat(isUser?.educationInformation?.tuitionFee || 0), "$")}
                  </h4>
                </div>{" "}
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Living Expenses</h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">
                    {/* {isUser?.educationInformation?.currency}{" "} */}
                    {CurrencyFormatter(parseFloat(isUser?.educationInformation?.livingExpenses || 0), "$")}
                  </h4>
                </div>{" "}
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Total Funding Requirement </h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">
                    {/* {isUser?.educationInformation?.currency}{" "} */}
                    {CurrencyFormatter(parseFloat(isUser?.educationInformation?.totalFundingRequirement || 0), "$")}
                  </h4>
                </div>{" "}
                <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                  <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Amount Available</h3>
                  <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">
                    {/* {isUser?.educationInformation?.currency}{" "} */}
                    {CurrencyFormatter(parseFloat(isUser?.educationInformation?.amountAvailable || 0), "$")}
                  </h4>
                </div>
                {isUser?.acceptanceStatus !== "ACCEPT" && (
                  <TextArea
                    label={"Comment"}
                    type="text"
                    value={formik.values.comment}
                    hint={"Not more than 200 characters"}
                    onBlur={formik.handleBlur("comment")}
                    onChange={formik.handleChange("comment")}
                    error={formik.touched.comment && formik.errors.comment}
                  />
                )}
              </div>
            </div>
            {isUser?.acceptanceStatus !== "ACCEPT" && (
              <div className=" w-full flex  100:gap-[16px] 100:flex-col-reverse phone:flex-row  justify-between items-center">
                <div onClick={declineRequest} to={"/approver/dashboard"} className=" 100:w-full phone:w-fit">
                  <button className="leading-[20px] bg-white rounded-lg shadow border border-[#aab0b6]  text-[#4a4f54] text-base flex items-center justify-center font-medium h-[48px] px-7  100:w-full phone:w-[128px] ">Reject</button>
                </div>
                <button
                  onClick={approveRequest}
                  className="leading-[20px] border-[#0c4aff] border-solid border-[1px] rounded-[8px]  bg-[#0c4aff]   text-base text-[#fff] flex items-center justify-center font-medium h-[48px] px-[16px]  100:w-full phone:w-[128px] "
                >
                  {isLoading ? "Loading..." : "Approve"}
                </button>
              </div>
            )}
          </div>
          <div className="h-screen  100:mt-[32px]   100:h-fit 100:w-full md:w-[400px]   px-8 py-[50px] bg-white flex-col justify-start items-center gap-8 inline-flex">
            <div className="flex-col w-full border-b border-b-[#E5E7E8] pb-[16px] justify-start items-center gap-[16px] inline-flex">
              <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Date Submitted</h3>
                <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">{formatDate(new Date(isUser.createdAt))}</h4>
              </div>{" "}
              <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Time Initiated</h3>
                <h4 className="self-stretch text-[#4a4f54] text-base font-semibold  leading-tight">
                  {new Date(isUser.createdAt).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </h4>
              </div>
            </div>
            <div className="flex-col w-full border-b border-b-[#E5E7E8] pb-[16px] justify-start items-center gap-[16px] inline-flex">
              <div className="justify-start w-full items-center gap-3 inline-flex">
                <img src={no} alt="" /> <h4 className="self-stretch text-[#4a4f54] text-sm font-medium leading-tight">{isUser.id}</h4>
              </div>{" "}
              <div className="justify-start w-full items-center gap-3 inline-flex">
                <img src={emailu} alt="" /> <h4 className="self-stretch text-[#4a4f54] text-sm font-medium leading-tight">{isUser?.email}</h4>
              </div>{" "}
              <div className="justify-start w-full items-center gap-3 inline-flex">
                <img src={user} alt="" />{" "}
                <h4 className="self-stretch text-[#4a4f54] text-sm font-medium leading-tight">
                  {isUser?.firstName} {isUser?.lastName}
                </h4>
              </div>{" "}
              <div className="justify-start w-full items-center gap-3 inline-flex">
                <img src={map} alt="" /> <h4 className="self-stretch text-[#4a4f54] text-sm font-medium leading-tight">{isUser?.address}</h4>
              </div>{" "}
            </div>
            <div className="flex-col w-full border-b border-b-[#E5E7E8] pb-[16px] justify-start items-center gap-[16px] inline-flex">
              <div className="justify-start w-full items-center gap-3 inline-flex">
                <img alt="" src={linkedin} />{" "}
                <a href={isUser?.educationInformation?.linkedInAddress} className=" truncate text-[#4a4f54] text-sm font-medium leading-tight">
                  {" "}
                  {isUser?.educationInformation?.linkedInAddress}
                </a>
              </div>{" "}
              <div className="justify-start w-full items-center gap-3 inline-flex">
                <img alt="" src={Instagram} />{" "}
                <a href={isUser?.educationInformation?.instagramLink} className="truncate text-[#4a4f54] text-sm font-medium leading-tight">
                  {" "}
                  {isUser?.educationInformation?.instagramLink}
                </a>
              </div>{" "}
              <div className="justify-start w-full items-center gap-3 inline-flex">
                <img alt="" src={x} />{" "}
                <a href={isUser?.educationInformation?.twitterLink} className="truncate text-[#4a4f54] text-sm font-medium leading-tight">
                  {" "}
                  {isUser?.educationInformation?.twitterLink}
                </a>
              </div>{" "}
              <div className="justify-start w-full items-center gap-3 inline-flex">
                <img alt="" src={facebook} />{" "}
                <a href={isUser?.educationInformation?.facebookProfile} className="truncate text-[#4a4f54] text-sm font-medium leading-tight">
                  {" "}
                  {isUser?.educationInformation?.facebookProfile}
                </a>
              </div>{" "}
            </div>
            <div className="flex-col w-full border-b border-b-[#E5E7E8] pb-[16px] justify-start items-center gap-[16px] inline-flex">
              <div className="justify-start w-full flex-col items-start gap-2 inline-flex">
                <h3 className="self-stretch text-[#6c757d] text-sm font-normal  leading-tight">Status</h3>
                <div
                  className={`h-[22px] gap-[4px] mix-blend-multiply px-2 py-0.5 ${isUser?.acceptanceStatus === "ACCEPT" ? "bg-[#f1fcf2]" : isUser?.acceptanceStatus === "REJECT" ? "bg-red-100" : "bg-[#ffffea]"}  leading-[18px] ${
                    isUser?.acceptanceStatus === "ACCEPT" ? "text-[#1f7634]" : isUser?.acceptanceStatus === "REJECT" ? "text-red-500" : "text-[#bb6902]"
                  } text-center  text-xs font-medium rounded-2xl justify-center items-center flex`}
                >
                  {" "}
                  {isUser?.acceptanceStatus === "ACCEPT" ? <Completed /> : isUser?.acceptanceStatus === "REJECT" ? <Reject /> : <Pending />}
                  {/* {isUser?.acceptanceStatus} */}
                  {isUser?.acceptanceStatus ? isUser.acceptanceStatus.charAt(0).toUpperCase() + isUser.acceptanceStatus.slice(1).toLowerCase() : ""}
                  {/* Pending Approval */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {accept && (
        <>
          <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start isUsers-center flex">
            <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
              <div
                onClick={() => {
                  setAccept(false);
                }}
                className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  "
              >
                <img className=" " src={cancel} alt="Cancel" />
              </div>
            </section>
            <section className=" 100:w-[80%] h-full iphone:w-full flex justify-center  items-center">
              <div className=" 100:w-full iphone:w-96 100:h-fit iphone:h-64 p-[24px] bg-white rounded-xl shadow flex-col justify-start items-center flex">
                <div className="w-full flex-col justify-start items-center gap-[32px] flex">
                  <div className="w-full flex-col justify-start items-center gap-5 flex">
                    <div className="w-12 h-12   justify-center flex  items-center  bg-emerald-100 rounded-3xl border-8 border-emerald-50  ">
                      <img className=" " src={confirm} alt="Confirm" />
                    </div>
                    <div className="w-full flex-col justify-start items-center gap-2 flex">
                      <h3 className="self-stretch text-center text-gray-900 text-lg font-medium  leading-7">Approved Successfully</h3>
                      <p className="self-stretch text-center text-gray-500 text-sm font-normal  leading-tight">This user has been approved successfully..</p>
                    </div>
                  </div>
                  <div
                    className="w-full"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <div className="leading-[20px] border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-fit px-[20px] py-[12px] w-full ">Back to Dashboard</div>
                  </div>{" "}
                </div>
              </div>
            </section>
          </div>
        </>
      )}
      {reject && (
        <>
          <div className="w-full h-[100%] top-0 left-0 fixed z-40  bg-slate-700 bg-opacity-60 backdrop-blur-lg flex-col justify-start items-center flex">
            <section className=" w-full flex justify-end pt-[25px] pr-[40px] items-end">
              <div onClick={() => setReject(false)} className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  ">
                <img className=" " src={cancel} alt="Cancel" />
              </div>
            </section>
            <section className=" 100:w-[80%] h-full iphone:w-full flex justify-center  items-center">
              <div className=" 100:w-full iphone:w-96 100:h-fit iphone:h-64 p-[24px] bg-white rounded-xl shadow flex-col justify-start items-center flex">
                <div className="w-full flex-col justify-start items-center gap-[32px] flex">
                  <div className="w-full flex-col justify-start items-center gap-5 flex">
                    <div className="w-12 h-12   justify-center flex  items-center  bg-emerald-100 rounded-3xl border-8 border-emerald-50  ">
                      <img className=" " src={confirm} alt="Confirm" />
                    </div>
                    <div className="w-full flex-col justify-start items-center gap-2 flex">
                      <h3 className="self-stretch text-center text-gray-900 text-lg font-medium  leading-7">Rejected Successfully</h3>
                      <p className="self-stretch text-center text-gray-500 text-sm font-normal  leading-tight">This user has been rejected successfully..</p>
                    </div>
                  </div>
                  <div
                    className="w-full"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <div className="leading-[20px] border-[#0C4AFF] border-solid border-[1px] rounded-[8px]  bg-[#0C4AFF] text-lg text-[#fff] flex justify-center font-medium h-fit px-[20px] py-[12px] w-full ">Back to Dashboard</div>
                  </div>{" "}
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default UserDetails;
