import axios from "axios";

export const ApiClient = () => {
  const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      Accept: "application/json",
    },
    timeout: 60000,
  });

  api.interceptors.request.use(
    async (config) => {
      const token =localStorage.getItem("ijeToken");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  async function refreshToken() {}
  function createAxiosResponseInterceptor() {
    const interceptor = api.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;

  
        // if (error.response.status === 401 || error.response.status === 400 || error.response.status === 403 ) {
        if (error.response.status === 403) {
          try {
            console.log("error", error.response.status);
            api.interceptors.response.eject(interceptor);


          } catch (err) {
            if (err.response && err.response.status === 400) {
              throw { response: { status: 401 } };
              //   await SecureStore.deleteItemAsync("accessToken");
              //   await SecureStore.deleteItemAsync("refreshToken");
              // Add logic to log the user out or redirect to login page
            }
            return Promise.reject(err);
          } finally {
            createAxiosResponseInterceptor();
          }
        }
        return Promise.reject(error);
      }
    );
  }

  createAxiosResponseInterceptor();

  const get = (path, params) => {
    return api.get(path, { params });
  };

  const post = (path, body) => {
    // console.log(body)
    return api.post(path, body);
  };

  const put = (path, body, params) => {
    return api.put(path, body, { params });
  };

  const patch = (path, body, params) => {
    return api.patch(path, body, { params });
  };

  const del = (path) => {
    return api.delete(path);
  };

  return {
    get,
    post,
    patch,
    put,
    del,
  };
};
